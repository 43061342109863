import AboutCoaches from '../Components/Coaching/AboutCoaches'
import CoachingServices from '../Components/Coaching/CoachingServices.js'
import CoachingPackages from '../Components/Coaching/CoachingPackages'

export default function Coaching() {
    return  ( 
      <div>
          <div className='md:container md:mx-auto' >
            <CoachingServices />
          </div>
          <div className='bg-dk-blue'>
            <CoachingPackages />
          </div>
      </div>
    
    ) 
}        