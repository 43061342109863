import React from 'react'

export default function CoachingSignUp() {
  return (
    <div className='font-quicksand'>
      <div className='flex justify-center gap-5'> 
        <div>
          <button  className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'><a href='https://www.paypal.com/ncp/payment/ALLX6RUHVC5VC'target='_BLANK'>BUY NOW</a></button>
        </div>
        <div>
          <button  className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'><a href='https://calendar.app.google/Ns2jVZBwNDkib74z8' target='_BLANK'>BOOK SESSION</a></button>
        </div>
     </div>
     <div className='flex justify-center'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfc_CdfWgQMEP-IBhIUAkbnAXPWy8fwfkWh02RvRb0XibIZ1A/viewform?embedded=true" width="900" height="2239" frameborder="0" marginheight="0" marginwidth="0"></iframe>
    </div> 
   </div>
  )
}
