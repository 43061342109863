import { Link } from "react-router-dom"
import SpeakingPic from "../images/speaking2.png"

export default function Speaking() {
    return  ( 
      <div className="p-5 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${SpeakingPic})` }}>
           <h1 className="font-greatvibes text-5xl text-pink text-center mb-5">Retreats, Workshops, and Seminars</h1>
          <div className='container mx-auto font-quicksand text-xl text-pink'>
              <div className="lg:gap-10">
                <div>
                  <p>As a <span className="font-yeseva text-3xl font-extrabold">motivational speaker</span>, I empower women to live their best lives every day by sharing practical personal growth principles that truly work. My mission is to inspire women to embrace their potential, overcome challenges, and create a life they love. Through powerful storytelling and actionable insights, I help women navigate life’s transitions with confidence and purpose. My goal is to spark lasting transformation and guide women on their journey to personal and professional fulfillment. </p>
                  <br></br>
                <p>In addition to my certificaton as a Maxwell Leadership Speaker, Trainer and Coach, I offer customized presentations to fit your corporate or ministry needs and budget such as:</p>
                <ul className="  list-disc m-10 font-yeseva text-3xl font-extrabold">
                        <li>Keynote speaker for corporate or ministry retreats</li>
                        <li>Half day and full day workshop/seminar</li>
                        <li>In-house training</li>
                        <li>Lunch and Learn</li>
                </ul>
                <p className="">I will,  at your request, incorporate your organization’s meeting theme, mission/vision statement or build on your existing training schedule to best fit the needs of your group.</p>
                <br/>
                <Link to="/booking"> <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Booking Request</button></Link>
           
                </div>
                </div> 
          </div> 
      </div>
  )
  }