import React from 'react'
import FlyerExample from "../../images/Flyer-Example.png"
import WebExample from "../../images/Website-Example.png"
import LogoExample from "../../images/Logo-Example.png"
export default function Branding() {
  return  ( 
    <div>
         <h1 className="font-greatvibes text-7xl text-dk-blue text-center mb-5">Branding Services</h1>
        <div className='container mx-auto'>
            <h2 className="text-3xl lg:text-3xl lg:mb-4 lg:font-bold">Websites</h2>
            <div className="m-4 p-4 lg:grid lg:grid-cols-3 lg:gap-10 lg:m-5">
               <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={WebExample} alt="" />
                <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={WebExample} alt="" />
                <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={WebExample} alt="" />
        </div> 
        <div className='text-center text-2xl'>Need a website?</div>
        <h2 className="text-3xl lg:text-3xl lg:mb-4 lg:font-bold">Flyers</h2>
        <div className="m-4 p-4 lg:grid lg:grid-cols-3 lg:gap-10 lg:m-5">
            <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={FlyerExample} alt="" />
            <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={FlyerExample} alt="" />
           <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={FlyerExample} alt="" />
            </div>
         
        <div className='text-center text-2xl'>Need flyers?</div>
        <h2 className="text-3xl lg:text-3xl lg:mb-4 lg:font-bold">Logos</h2>
        <div className="m-4 p-4 lg:grid lg:grid-cols-3 lg:gap-10 lg:m-5">
            <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={LogoExample} alt="" />
            <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={LogoExample} alt="" />
            <img className="transform hover:scale-105 transition duration-500" width="450px" height="350px" src={LogoExample} alt="" />
              
        </div> 
        <div className='text-center text-2xl'>Need a logo?</div>
        </div> 
    </div>
)
}
