import trainings from "../../data/trainings.js"   

export default function VariousTrainings() {


    return ( 
      <div >
         <div className="lg:grid grid-cols-3 gap-10 p-10">  
            {trainings.map((training, i) => (     
              <div className="text-center shadow-md shadow-pink p-10 rounded-xl my-6 font-quicksand text-dk-blue bg-white">
                <div className="flex justify-center"><img key={i} className=" lg:rounded-md lg:overflow-hidden" width="250px" height="150px" src={training.img} alt="" /></div>
                <h2 className="text-xl md:text-2xl  lg:mb-4 lg:font-bold">{training.title}</h2>
                <p className="text-sm text-justify lg:pb-3  lg:text-justify">{training.description}</p>
                <a href={training.link} className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>{training.button}</a>


              </div>
            
            ))}
        
        </div>   
        </div>
    )
}