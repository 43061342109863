import MainTraining from "../Components/Training/MainTraining.js"
import VariousTrainings from "../Components/Training/VariousTrainings.js";
import MMGTraining from "../Components/Training/MMGTraining.js";
import Certifications from "../Components/Certifications.js";
import Training from "../images/Training.jpg"

export default function Trainings(){
    return (
        <div>
            <div className="bg-no-repeat" style={{ backgroundImage: `url(${Training})` }}>
                <div className=" backdrop-blur-sm lg:container mx-auto">
                    <div><MMGTraining /> </div>
                </div>
            </div>
            <div>
                <div><MainTraining /></div>
                <div className="bg-dk-blue"><VariousTrainings /></div>
                <div className="pt-5"><Certifications /></div>
            </div> 
         </div>
    );
}

