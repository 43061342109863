import { useRef } from "react"
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_tv43tvm', 'template_ukam2mp', form.current, 'J_RZqGwfU2pfxmsaE') 
       .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
    return (
            <section className="h-screen mb-0 py-6">
            <div className="grid relative font-quicksand max-w-6xl grid-cols-1 p-32 rounded-lg bg-[hsla(0,0%,100%,0.75)] px-6 mx-auto lg:px-8 md:grid-cols-2">
            <div className="py-6 md:py-0 md:px-6">
                <h2 className="text-4xl lg:text-3xl lg:mb-4 lg:font-bold">Ready to Go Further?</h2>
                <p className="text-xl text-justify lg:text-md lg:pb-3 lg:text-justify">Taking your goals and dreams off the page and implementing them in your life is hard work. Don’t go after it alone.  “Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort." —P. J. MEYER</p>
            </div>
            <div>
                <form ref={form} onSubmit={sendEmail} className="space-y-6">
                    <div>
                        <input name='user_name' id="name" type="text" placeholder="Name" className="w-full p-3 rounded uppercase dark:bg-gray-800" required />
                    </div>
                    <div>
                        <input name='user_email' id="email" type="email" placeholder="Email" className="w-full uppercase p-3 rounded dark:bg-gray-800" required />
                    </div>
                    <div>
                        <input name='user_phone' id="tele" type="text" placeholder="Phone Number" className="w-full uppercase p-3 rounded dark:bg-gray-800" required />
                    </div>
                    <div>
                        <textarea name='message' id="message" placeholder="Message" rows="3" className="w-full p-3 uppercase rounded dark:bg-gray-800" required ></textarea>
                    </div>
                    <div  className="mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink">
                        <button 
                            type="submit"
                          > 
                           Send
                        </button>
                    </div>
                </form>
            </div>
            </div>
            </section>
    ); 
}