
import React, { useState } from "react";
// import { UserContext } from "../../Providers/UserProvider";
import { auth } from "../../Services/Firebase.js"
import { signInWithEmailAndPassword } from "firebase/auth";



export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const login = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
    })
      .catch ((err) => {
        console.log(err);
      })
  }

  return (    
      <div className="font-sans login bg-cover">
      <div className="container mx-auto flex flex-1 black justify-center items-center">
          <div className="w-full max-w-lg">
            <div className="leading-loose">
              <form onSubmit={login} className="max-w-sm m-4 p-10 bg-white bg-opacity-25 rounded shadow-xl">
                  <p className="text-center text-dark-purple text-lg font-bold">LOGIN</p>
                    <div className="">
                      <label className="block text-sm text-white">E-mail</label>
                      <input type="email" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-5 py-1 text-gray-700 bg-light-green rounded focus:outline-none focus:bg-white" id="email"  placeholder="Email" aria-label="email" required />
                    </div>
                    <div className="mt-2">
                      <label className="block  text-sm text-white">Password</label>
                       <input className="w-full px-5 py-1 text-gray-700 bg-light-green rounded focus:outline-none focus:bg-white"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       type="password" id="password" placeholder="Password" arial-label="password" required />
                        </div>
                      <div className="mt-4 items-center flex justify-between">
                        <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'  
                        type="submit">Log In</button>

                      <a className="inline-block right-0 align-baseline font-bold text-sm text-500 text-dark-blue hover:text-blue"
                        href="#">Forgot Password?</a>
                    </div> 
              </form>
            </div>
          </div>
        </div>
      </div>
     
  );
}