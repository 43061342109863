

export default function Certifications(){
    return (
        <div className="flex justify-center">
            <img src={require("../images/ML-Certified-Team-Logo_onindigo.jpg")} className="rounded-md object-fill" alt="Certification" width="100px" height="100px" />
            <img />
            <img />
        </div>
      
    );
}