const trainings = [
    {
        img: require("../images/web_serv.png"),
        title: "How To Be A R.E.A.L. Success",
        description: " Success is not a destination. It is a process – a lifelong strategy of building on strengths, minimizing weaknesses, and focusing on the people and points of life that are most important. In How to be a REAL Success, we will help you understand the keys you need to succeed in life. Whether you are a civil servant or a corporate executive, you will achieve great things by understanding four very important success-building areas: Relationships, Equipping, Attitude, and Leadership. ",
        button: "Sign Up For Updates",
        link: "/Contact",
    },
    {
        img: require("../images/life-coach.png"),
        title: "Becoming A Person of Influence",
        description: "Are you ready to increase your leadership potential? No matter your leadership role, be it a parent, teacher, pastor, or business executive, you are a person of influence. Increasing your influence equals increasing your leadership...read more.",
        button: "Sign Up For Updates",
        link: "/Contact",
    },
    {
        img: require("../images/PutYourDreamToTheTestMastermind_Ad2.png"),
        title: "Put Your Dreams To The Test",
        description: "Your dream has power. It can inspire you. It can empower you. But will it reward you? In this course to reaching your dreams, we will learn how to do the things needed to make your dream come true. ",
        button: "Sign Up For Updates",
        link: "/Contact",
    },
];
export default trainings;