
export default function CoachingServices() {
  return  ( 
    <div>
      <div className='p-2'>
         <h1 className="font-greatvibes text-5xl text-dk-blue text-center mb-5">The Coaching Relationship</h1>
         <p className="text-xs text-center font-quicksand">"Though good advice lies deep within the heart, a person [coach] with understanding will draw it out." ~Proverb 20:5</p>
         <div className='font-quicksand pt-2'>
          <h1 className='text-2xl font-bold md:text-5xl'>What is Coaching?</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-lg'>
                Coaching is a powerful and meaningful relationship that helps women think through their own solutions within a time-framed session filled with powerful questioning and way-forward thinking. 
              </p>
            </div> 
        </div> 
        
        <div className='font-quicksand'>
          <h1 className='pt-2 text-2xl font-bold md:text-5xl'>Coaching Process:</h1>
            <div className=" lg:gap-10 lg:m-5">
              <p className='text-lg'>Through coaching, strategies are implemented to help gain awareness, pinpoint obstacles, clarify vision, and indentify solutions allowing for deep reflection that helps gain both sight and insight into your life.
              </p>
            </div> 
        </div> 

        <div className="font-quicksand">
        <h1 className="pt-2 text-2xl font-bold md:text-5xl ">Here's what we offer:</h1>
        <ul className="m-5 list-disc font-quicksand md:text-lg lg:text-xl">
        <li className=""><span className="font-yeseva text-xl text-pink underline md:text-3xl">Personalized Coaching</span>: Our certified life coaches will work with you one-on-one to explore your strengths, passions, and values, helping you create a personal brand that truly represents who you are.</li>

        <li><span className="font-yeseva text-xl  text-pink underline md:text-3xl">Tailored Support for Life Transitions</span>: Whether you're stepping into a new career, navigating empty nest syndrome, or redefining your relationships, we provide the support and tools you need to transition smoothly and confidently.</li>

        <li><span className="font-yeseva text-xl text-pink underline md:text-3xl">Comprehensive Branding Guidance</span>: From crafting your brand voice to developing a professional online presence, we offer resources and guidance to help you stand out and connect meaningfully with your audience.</li>

        <li><span className="font-yeseva text-xl text-pink underline md:text-3xl">Empowerment and Growth</span>: We believe in empowering women to take control of their narrative and present their best selves to the world. Our coaching approach is designed to build your confidence, resilience, and vision for the future.</li>
        </ul>   
      </div>
      </div> 
      </div>
)
}