import React  from 'react'
import { Login } from '../Components/Auth/Login.js'
// import { SignUp } from '../Components/Auth/SignUp.js'
import { UserProvider } from '../Providers/UserProvider.js'
export const LoginPage = () => {

    return (
        <div>
            {/* <SignUp /> */}
            <Login />
          <UserProvider />
        </div>
    )
}