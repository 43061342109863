import { Link } from "react-router-dom";


export default function Main() {
    return (
     <div className="md:container md:mx-auto md:min-h-screen">
            <div className="text-sm p-2 text-pink font-quicksand md:text-xl md:p-5">  
                <h2 className="text-sm animate-bounce md:text-3xl">CERTIFIED SPEAKER, TRAINER & COACH.</h2>
                <h2 className="text-4xl font-greatvibes pt-5 md:text-5xl lg:text-6xl">Looking for a Coach? </h2>
                <p className="pt-5">Are you navigating a significant life transition? Whether you're becoming a mother or an empty nester, starting a new career, experiencing a change in relationships, or dealing with a major life event, living a more balanced and authentic way is more important than ever.</p> 
                <p className="pt-5 ">Hi, I'm Coach E and here at <span className="font-yeseva md:text-3xl">LIVE A BETTER LIFE 24•7</span>, I specialize in helping women like you define and embrace your unique identity during these pivotal moments. My life coaching services provide personalized guidance and practical strategies to empower you to craft a personal brand that is authentic, confident, and aligns with your true self.</p>               
            </div>
            <div className="p-10">
                 <button className='font-quicksand px-7 py-3 text-md font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink animate-pulse'> <Link to="/coaching">More Info</Link></button>
             </div>
       
        {/* <div className="md:flex lg:flex min-h-screen relative">
            <div className="font-quicksand text-3xl">
                <div className="flex justify-center">
                    <div className="">
                        <h2 className="text-2xl">FREE! FREE! FREE!</h2>
                        <h2 className="font-greatvibes md:text-4xl lg:text-7xl mt-10 mb-10 ml-14"> </h2>
                        <p className="mt-10">Download this workbook with actionable steps you can take today. </p> 
                        <button className='mt-4 px-7 py-3 text-md font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'> <Link to="/coachingSignUp">Workbook</Link></button>
                    </div>
                </div>
            </div>
        </div> */}

    
        </div>
    )
}