import React, { useEffect, useState } from "react";
//noice here we are refrenceing the service we set up earlier
import { auth } from "../Services/Firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const UserProvider = (props) => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => { 
      const listen = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user)
        } else {
          setAuthUser(null)
        }
      });

      return () => {
        listen()
      }
    }, []);

      const userSignOut = () => {
        signOut(auth).then(() => {
          console.log('sign out successful')
        }).catch(error => console.log(error))
      }

    return (
      <div>
        { authUser ? <><p>{`Signed In as ${authUser.email}`}</p><button onClick={userSignOut}>Sign Out</button></> : <p>Signed Out</p>}
      </div>
    );
  };