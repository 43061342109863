export default function MMGTraining(){
    return (
        <div className=" p-2 font-quicksand text-md  text-pink md:container">
            <h1 className="font-greatvibes text-5xl text-dk-blue text-center mb-5">Join a Mastermind Group</h1>
            <h2 className="font-yeseva text-2xl font-extrabold md:text-3xl">Key Benefits</h2>
            <p className="md:text-xl md:pt-4">My mastermind groups are designed to harness the energy, commitment, and excitement that each participant brings. With a focus on empowerment, self-discovery, and personal growth, these sessions combine masterminding, peer brainstorming, education, accountability, and support to sharpen both your business and personal skills. By offering fresh perspectives and innovative ideas, my masterminds are geared to help you achieve success in every aspect of your life.
            <br /><br />
            These 4 to 8-week sessions provide a unique opportunity to collaborate with like-minded individuals who are committed to elevating their personal and professional development. The support and insights from others who share your drive and focus will empower you to see your goals and action plans in a new light, guiding you towards greater success.</p>
<br />
            <h2 className="font-yeseva text-2xl font-extrabold md:text-3xl">Other Benefits:</h2>
            <ul className="p-2 list-disc ml-10 md:text-xl md:pt-4">
                <li>Increase your own experience and confidence</li>
                <li>Sharpen your business and personal skills</li>
                <li>Add an instant and valuable support network</li>
                <li>Get honest feedback, advice and brainstorming</li>
                <li>Borrow on the experience and skills of the other members</li>
                <li>Study Leadership, Communications and Growth principles/practices in a group setting, create an action plan and have the group hold you accountable for fulfilling your plan and goals.</li>
                <li>Receive critical insights into yourself</li>
                <li>Optimistic peer support in maintaining a positive mental attitude</li>
                <li>A sense of shared endeavor – there are others out there!</li>

            </ul>
            
            
            
            </div>
      
    );
}