import Main from "../Components/Main"
import WhyLABL from "../Components/WhyLABL"
import YTCard from "../Components/YTCard"
import ServicesListing from "./ServicesListing"
import backgroundImage from "../images/office_bg.png"
import turnpage from "../images/TurnPage.png"

export default function Home() {
    return (
      <div >
               <div className="bg-cover bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage})` }}>
                  <Main />
                </div> 
                <ServicesListing />
                <div className="bg-cover bg-no-repeat" style={{ backgroundImage: `url(${turnpage})` }}><WhyLABL /></div>
                {/* <YTCard embedId="pf9QX5D_oYM"/> */} 
       </div>
    )
}