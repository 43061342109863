import { useState } from "react"
import PayPal from "../Components/PayPal";

export default function Donate() {
    const [checkout, setCheckout] = useState(false)
    return (
       <div>
        {checkout ? (
            <PayPal />
       )    :   ( 
            <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'
            onClick={() => {
                setCheckout(true);
        }}
        >
            Checkout
        </button>
        )}
       </div>
    )
}